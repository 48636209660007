<template>
  <div class="Thankyou ">
    <div class="content1 pc-type">
      <div>
        <div class="text1">感谢你下载 RealPlayer！</div>
        <div class="text2">文件下载完成后</div>
        <div class="text3">你需要安装它</div>
        <div class="text4"><span>1.</span>单击文件</div>
        <div class="text4"><span>2.</span>点击“是”并按步骤安装程序</div>
        <div class="text4"><span>3.</span>启动 RealPlayer</div>
        <div class="text5">下载出现问题？<span @click="downLoad()">再试一次</span></div>
      </div>
      <div><img :src="alertStatus===3?require('@/img/thankyou06.svg'):require('@/img/thankyou01.svg')" alt=""></div>
    </div>
    <div class="content2 pc-type">
      <div class="content2-l">
        <img src="@/img/thankyou02.png" alt="">
      </div>
      <div class="content2-r">
        <div class="text6">获取 RealPlayer 移动版</div>
        <div class="scancode">
          <div class="scancode-google" @click="openLink(toGoogleLink)">
              <img src="@/img/googleplay.svg" alt="">
              <img src="@/img/googleplay_QR.png" alt="">
          </div>
          <div class="scancode-appstore"  @click="openLink(toAppStoreLink)">
              <img src="@/img/appstore.svg" alt="">
              <img src="@/img/appstore_QR.svg" alt="">
          </div>
        </div>
        <div class="text7">使用照相手机扫描二维码,</br>安装应用程序</div>
        <div class="text8">RealPlayer 的必备伴侣</div>
        <div class="text9"><img src="@/img/thankyou03.png" alt=""><span>在手机上播放任意视频，并投射到大屏幕</span> </div>
        <div class="text9"><img src="@/img/thankyou03.png" alt=""><span>随时随地访问你的 RealPlayer 电脑媒体库</span> </div>
        <div class="text9"><img src="@/img/thankyou03.png" alt=""><span>从电脑媒体库轻松构建你的收藏</span> </div>
      </div>
    </div>
    <div class="hereFile pc-type" v-if="alertStatus==true">
      <div class="hereFileTop"><img src="@/img/thankyou05.svg" alt="" @click="closeAlert()"></div>
      <div class="hereFileContent">
        <img src="@/img/thankyou04.svg" alt="">
        <div>
          <div class="text10">这是你的文件</div>
          <div class="text11">单击可打开或显示在文件夹中</div>
        </div>
      </div>
    </div>


    <div class="content1 mobile-type">
      <div>
        <div class="text1">感谢你下载 RealPlayer！</div>
        <div class="text2">文件下载完成后,你需要安装它</div>
        <div class="text3"></div>
        <div class="text4"><span>1.</span>单击文件</div>
        <div class="text4"><span>2.</span>点击“是”并按步骤安装程序</div>
        <div class="text4"><span>3.</span>启动 RealPlayer</div>
        <div class="text5">下载出现问题？<span @click="downLoad()">再试一次</span></div>
      </div>
      <!-- <div><img src="@/img/thankyou01.svg" alt=""></div> -->
    </div>
    <div class="content2 mobile-type">
      <div class="content2-l">
        <img src="@/img/thankyou02.png" alt="">
      </div>
      <div class="content2-r">
        <div class="text6 t-c">获取 RealPlayer 移动版</div>
        <div class="scancode">
          <div class="scancode-google" @click="openLink(toGoogleLink)" v-if="type == 'andriod'">
              <img src="@/img/googleplay.svg" alt="">
              <img src="@/img/googleplay_QR.png" alt="">
          </div>
          <div class="scancode-appstore"  @click="openLink(toAppStoreLink)" v-if="type == 'ios'">
              <img src="@/img/appstore.svg" alt="">
              <img src="@/img/appstore_QR.svg" alt="">
          </div>
        </div>
        <div class="text7">使用照相手机扫描二维码,安装应用程序</div>
        <div class="text8">RealPlayer 的必备伴侣</div>
        <div class="text9"><img src="@/img/thankyou03.png" alt=""><span>在手机上播放任意视频，并投射到大屏幕</span> </div>
        <div class="text9"><img src="@/img/thankyou03.png" alt=""><span>随时随地访问你的 RealPlayer 电脑媒体库</span> </div>
        <div class="text9"><img src="@/img/thankyou03.png" alt=""><span>从电脑媒体库轻松构建你的收藏</span> </div>
      </div>
    </div>


    <!-- <div class="hereFile mobile-type" v-if="alertStatus">
      <div class="hereFileTop"><img src="@/img/thankyou05.svg" alt="" @click="closeAlert()"></div>
      <div class="hereFileContent">
        <img src="@/img/thankyou04.svg" alt="">
        <div>
          <div class="text10">这是你的文件</div>
          <div class="text11">单击可打开或显示在文件夹中</div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
  import settingLink from '@/js/global.js'
  export default {
   name: 'Thankyou',
    data(){
      return{
        toGoogleLink:settingLink.googleLink,
        toAppStoreLink:settingLink.appStoreLink,
        alertStatus:true,
        // 当前设备类型
        type:'', // pc/andriod/ios
        from:'', // 下载按钮在哪个界面触法
      }
    },
    components: {},
    created:function(){
      this.from = this.$route.query.from; // 获取下载按钮来源
      window.scrollTo(0,0);
      var type = window.localStorage.getItem('type'); // 获取当前设备类型
      this.type = type;
      this.downLoad();


      // 浏览器为谷歌 下载在下面   else 上面
      var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
      if (userAgent.indexOf('Chrome') > -1 && userAgent.indexOf('Edg') == -1) {
        this.alertStatus = true;
      }else{
        console.log('nonono9')
        this.alertStatus = 3;

      }
    },
    methods:{
      openLink:function(url,type=false){
        if(type){
           this.$router.push(url);
        }else{
          window.open(url,'_blank');
        }
      },
      closeAlert:function(){
        this.alertStatus = false;
      },
      downLoad:function(){
        var link;
        var type = window.localStorage.getItem('type'); // 获取当前设备类型
        var from = this.from;
        if(type == 'pc'){
          // real                   downLoadLink_windows_real
          // realplayer             downLoadLink_windows_realplayer
          // realplayer_features    downLoadLink_windows_realplayer_features
          // realplayer_pricing    downLoadLink_windows_realplayer_pricing
          // realplayer_mobile    downLoadLink_windows_realplayer_mobile
          switch(from)
          {
              case 'real':
                  link = settingLink.downLoadLink_windows_real;
                  break;
              case 'realplayer':
                  link = settingLink.downLoadLink_windows_realplayer;
                  break;
              case 'realplayer_features':
                  link = settingLink.downLoadLink_windows_realplayer_features;
                  break;
              case 'realplayer_pricing':
                  link = settingLink.downLoadLink_windows_realplayer_pricing;
                  break;
              case 'realplayer_mobile':
                  link = settingLink.downLoadLink_windows_realplayer_mobile;
                  break;
              default :
                  link = settingLink.downLoadLink_windows_real;
              break;
          }
        }else if (type == 'mac'){
          link = settingLink.downLoadLink_mac;

        }else if (type == 'andriod'){
          link = settingLink.downLoadLink_andriod;
          
        }else if (type == 'ios'){
          link = settingLink.downLoadLink_iphone;

        }
        window.open(link,'_self')

      }
    }
}
</script>
<style scoped>

  @media screen and (min-width: 480px) {
    .mobile-type{display: none !important;}
    .content1{width:68%;min-height:356px;display: flex;margin:0 auto;padding-top:10px;}
    .content1>div:nth-of-type(1){width:51%;padding-left: 24px;}
    .content1 img{width: 350px;height:280px;margin-left: -20px;}
    .text1{font: normal normal 22px/18px Red Hat Display;color: #00a1ff;margin:22px 0 11px;}
    .text2{font: bold normal 30px/50px Red Hat Display;color: #1f1f1f;margin:22px 0 0 ;}
    .text3{font: bold normal 30px/50px Red Hat Display;color: #1f1f1f;margin:0 0 11px ;}
    .text4{font-size: 14px;color: #333;}
    .text4 span{color: #00a7ff;font: normal bold 16px/26px Red Hat Display;}
    .text5{font: normal bold 18px/22px Red Hat Display;padding-top: 20px;}
    .text5 span{color: #00a7ff;text-decoration: underline;cursor: pointer;}

    .content2{min-height:624px;background: transparent linear-gradient(180deg,#0098f1 0,#017ec7 100%) 0 0 no-repeat padding-box;color: #fff;
      display: flex;padding: 37px;box-sizing: border-box;
    }
    .content2-l{margin-left: 16.66%;}
    .content2-l img{height: 460px;display: block;margin:0 20px;}
    .content2-r{padding:22px 0 0 26px;width: 41.666%;}
    .text6{font: normal bold 34px/43px Red Hat Display;}
    .text7{font: normal normal 15px/21px Red Hat Display;    margin-left: 24px;padding: 20px;}
    .text8{text-align: left;font: normal normal 26px/30px Red Hat Display;margin-bottom: 10px;}
    .text9{display: flex;align-items: center;font: normal normal 18px/20px Red Hat Display;height:46px;}
    .text9 img{margin-right: 10px;}
    .text9 span{padding-top: 10px;}

    /* 二维码 */
    .scancode{display: flex;}
    .scancode-google{width: 50%;cursor: pointer;display: flex;flex-direction: column;padding-left: 30px;}
    .scancode-google img:nth-child(1){display: block;max-width: 126px;height: 50px;margin:30px 0 10px;}
    .scancode-google img:nth-child(2){width: 126px;height: 126px;}
    .scancode-appstore{width: 50%;cursor: pointer;display: flex;flex-direction: column;}
    .scancode-appstore img:nth-child(1){display: block;max-width: 126px;height: 50px;margin:30px 0 10px;}
    .scancode-appstore img:nth-child(2){width: 126px;height: 126px;}

    /* 这是你的文件 */
    .hereFile{width:545px;height:135px;background: #f2ac00 0 0 no-repeat padding-box;position: fixed;z-index: 1000;left: 0;bottom: 0;display: flex;flex-direction: column;}
    .hereFileTop{display: flex;justify-content: flex-end;padding-right: 30px;padding-top: 5px;}
    .hereFileTop img{display: block;width: 20px;height: 20px;cursor: pointer;}
    .hereFileContent{display: flex;align-items: center;flex:1;}
    .hereFileContent img{width: 40px;height:58px;display: block;margin: 0 28px;}
    .text10{margin-left: 30px;font: normal bold 22px/20px Red Hat Display;color: #1f1f1f;margin-bottom: 10px;}
    .text11{margin-left: 30px;font: normal normal 14px/20px Red Hat Display;color: #1f1f1f;}
  }
  @media screen and (min-width: 320px) and (max-width:480px){
    .pc-type{display: none !important;}
    .content1{width:100%;padding-top:10px;}
    .content1>div:nth-of-type(1){width:100%;padding-left: 24px;box-sizing: border-box;}
    .content1 img{width: 90%;margin: 0 auto;display: block;}
    .text1{font: normal normal 14px Red Hat Display;color: #00a1ff;margin:22px 0 11px;}
    .text2{font: bold normal 18px Red Hat Display;color: #1f1f1f;margin:22px 0 0 ;}
    .text3{font: bold normal 18px Red Hat Display;color: #1f1f1f;margin:0 0 11px ;}
    .text4{font-size: 14px;color: #333;}
    .text4 span{color: #00a7ff;font: normal bold 16px/26px Red Hat Display;}
    .text5{font: normal bold 18px/22px Red Hat Display;padding-top: 20px;margin-bottom: 20px;}
    .text5 span{color: #00a7ff;text-decoration: underline;cursor: pointer;}

    .content2{background: transparent linear-gradient(180deg,#0098f1 0,#017ec7 100%) 0 0 no-repeat padding-box;color: #fff;
      padding: 16px;box-sizing: border-box;width: 100%;
    }
    /* .content2-l{margin-left: 16.66%;} */
    .content2-l img{height: 230px;display: block;margin: 0 auto;}
    .content2-r{width: 90%;margin: 0 auto;}
    .text6{font: normal bold 18px Red Hat Display;}
    .text7{font: normal normal 15px/21px Red Hat Display;padding: 20px;}
    .text8{text-align: left;font: normal normal 18px Red Hat Display;margin-bottom: 10px;}
    .text9{display: flex;align-items: center;font: normal normal 14px Red Hat Display;height:46px;}
    .text9 img{margin-right: 10px;}
    .text9 span{padding-top: 10px;}

    /* 二维码 */
    .scancode{display: flex;justify-content: center;}
    .scancode-google{width: 50%;cursor: pointer;display: flex;flex-direction: column;}
    .scancode-google img:nth-child(1){display: block;max-width: 126px;height: 50px;margin:30px 0 10px;}
    .scancode-google img:nth-child(2){width: 126px;height: 126px;}
    .scancode-appstore{width: 50%;cursor: pointer;display: flex;flex-direction: column;}
    .scancode-appstore img:nth-child(1){display: block;max-width: 126px;height: 50px;margin:30px 0 10px;}
    .scancode-appstore img:nth-child(2){width: 126px;height: 126px;}

    /* 这是你的文件 */
    .hereFile{width:100%;height:65px;background: #f2ac00 0 0 no-repeat padding-box;position: fixed;z-index: 1000;left: 0;bottom: 0;display: flex;flex-direction: column;}
    .hereFileTop{display: flex;justify-content: flex-end;padding-right: 15px;padding-top: 5px;}
    .hereFileTop img{display: block;width: 10px;height: 10px;cursor: pointer;}
    .hereFileContent{display: flex;align-items: center;flex:1;}
    .hereFileContent img{width: 20px;height:28px;display: block;margin: 0 14px;}
    .text10{margin-left: 10px;font: normal bold 14px Red Hat Display;color: #1f1f1f;margin-bottom: 10px;}
    .text11{margin-left: 10px;font: normal normal 16px Red Hat Display;color: #1f1f1f;}
  }
  
</style>
